import unidecode from 'unidecode';
import emojiRegex from 'emoji-regex';

// Function to preserve emojis and transliterate the rest
// Function to preserve emojis and transliterate the rest
function preserveEmojis(text) {
	const regex = emojiRegex();
	let result = '';
	let lastIndex = 0;

	// Find all emojis in the text and apply unidecode only to the non-emoji parts
	let match;
	while ((match = regex.exec(text)) !== null) {
		const emoji = match[0];
		const emojiIndex = match.index;

		// Transliterate the part of the string before the emoji
		result += unidecode(text.slice(lastIndex, emojiIndex));
		// Append the emoji without transliterating it
		result += emoji;

		// Update the last index to continue processing
		lastIndex = regex.lastIndex;
	}

	// Append the remaining part of the text after the last emoji
	result += unidecode(text.slice(lastIndex));

	return result;
}

/**
 * Function to decode caption characters to ensure consistent posts across platforms
 * @param caption
 * @returns string
 */
export const cleanCaption = (caption: string) => {
	return preserveEmojis(caption);
};
