import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Divider, Typography, Button, Tooltip } from '@mui/material';
import { Alert, AlertTitle, LoadingButton } from '@material-ui/lab';
import { saveAs } from 'file-saver';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DownloadIcon from '@material-ui/icons/Download';
import ShareIcon from '@material-ui/icons/Share';
import ContentCopy from '@material-ui/icons/ContentCopy';
import XIcon from 'src/packages/icons/X';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CanvaIcon from 'src/packages/icons/CanvaIcon';
import CapcutIcon from 'src/packages/icons/CapcutIcon';
import RefreshIcon from 'src/packages/icons/Refresh';
import { useUserStore } from 'src/packages/state';
import useSettings from 'src/packages/hooks/useSettings';
import { CustomCaptionsContext } from 'src/packages/custom-captions';
import useClipboard from 'react-use-clipboard';
import { useSavedSocialStore } from 'src/packages/lib/saved-social';
import { ImageUploadButton } from 'src/packages/media-upload/components/ImageUploadButton';
import { CaptionDetailsMessage } from './CaptionDetailsMessage';
import { generateCaptionVariations } from 'src/packages/services';
import { VideoVaultCard } from './VideoVaultCard';

const PreviewMedia = ({ post, handleClose, setPublish, styles }) => {
	const isVideoVaultPost = post?.node?.featuredPost?.videovault;
	const navigate = useNavigate();
	const { settings } = useSettings();
	const { enqueueSnackbar } = useSnackbar();
	const { useCustomCaptions, setRegeneratedCaption, regeneratedCaption } =
		useContext(CustomCaptionsContext);

	const { initialSavedPosts, setSavedPostsPayload, isUpdatingSavedPosts } =
		useSavedSocialStore();
	const [buttonStyle, setButtonStyle] = useState<
		'text' | 'outlined' | 'contained'
	>('outlined');
	const { userDetails } = useUserStore();
	const [copyButtonTooltip, setCopyButtonTooltip] = useState(null);

	const { preparedCaption, preparedHashtags } = useCustomCaptions(
		userDetails?.user_metadata?.captionSettings,
		post?.node?.featuredPost?.recommendedcaption,
		post?.node?.featuredPost?.popularhashtags,
		post?.node?.featuredPost?.popularhashtags,
		post?.node?.featuredPost?.videotextoverlay
	);

	const [caption, setCaption] = useState(regeneratedCaption || preparedCaption);
	const [isGeneratingCaption, setIsGeneratingCaption] = useState(false);

	const [isCaptionCopied, setCaptionCopied] = useClipboard(
		regeneratedCaption || preparedCaption
	);
	const [isHashtagsCopied, setHashtagsCopied] = useClipboard(preparedHashtags);
	const [isBothCopied, setIsBothCopied] = useClipboard(
		`${regeneratedCaption || preparedCaption}` +
			`\n` +
			`\n` +
			`${preparedHashtags}`
	);

	// state value to toggle icon
	const [isPostSaved, setIsPostSaved] = useState(
		initialSavedPosts.includes(post.node.databaseId)
	);

	const throwTooltip = (target: string) => {
		setCopyButtonTooltip(target);
		setTimeout(() => {
			setCopyButtonTooltip(null);
		}, 1000);
	};

	const regenerateCaption = async (caption: string) => {
		setIsGeneratingCaption(true);
		const result = await generateCaptionVariations(caption);

		if (result.status === 'error') {
			enqueueSnackbar(result.statusText, {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'error'
			});
			setIsGeneratingCaption(false);
			return;
		}

		const newCaption = result?.data?.caption[0].replace(/^"(.*)"$/, '$1');
		setCaption(newCaption);
		setRegeneratedCaption(newCaption);
		setIsGeneratingCaption(false);
	};

	const downloadImage = async () => {
		saveAs(
			post?.node?.featuredPost?.postimage?.mediaItemUrl,
			post?.node?.featuredPost?.postimage?.title
		);
	};

	return (
		<Box display={'flex'} flexDirection={'column'} flex={1}>
			{!userDetails?.user_metadata?.captionSettings && <CaptionDetailsMessage />}
			<Box sx={{ mb: '2rem' }} display="flex" flexDirection="column">
				{isVideoVaultPost && (
					<>
						<VideoVaultCard
							setCaptionCopied={setCaptionCopied}
							setHashtagsCopied={setHashtagsCopied}
							throwTooltip={throwTooltip}
							copyButtonTooltip={copyButtonTooltip}
							post={post}
						/>
					</>
				)}
				{!isVideoVaultPost && (
					<>
						<Typography
							color={'textPrimary'}
							sx={{ mt: 1, fontSize: '0.9rem' }}
							variant={'overline'}
						>
							Suggested Caption
						</Typography>
						<Typography id={'caption'} gutterBottom>
							{caption}
						</Typography>
						<LoadingButton
							sx={{ alignSelf: 'flex-end' }}
							loading={isGeneratingCaption}
							loadingPosition="start"
							startIcon={<RefreshIcon />}
							variant="contained"
							onClick={() => regenerateCaption(preparedCaption)}
						>
							Regenerate caption
						</LoadingButton>
					</>
				)}
			</Box>
			{!isVideoVaultPost && <Divider />}
			{!isVideoVaultPost && post?.node?.featuredPost?.popularhashtags && (
				<>
					<Box sx={{ mb: '1rem', mt: '1rem' }}>
						<Typography
							id={'hashtags'}
							color={'textPrimary'}
							sx={{ mt: 1, fontSize: '0.9rem' }}
							variant={'overline'}
						>
							Popular Hashtags
						</Typography>
						<Typography gutterBottom>{preparedHashtags}</Typography>
					</Box>
					<Divider />
				</>
			)}
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="flex-end"
				height="100%"
			>
				{!isVideoVaultPost && (
					<Box
						display={'flex'}
						justifyContent={'flex-end'}
						sx={{ mb: `${isVideoVaultPost ? '0' : '1rem'}`, mt: '1rem' }}
					>
						<Tooltip
							arrow
							placement={'top'}
							title={'Copied!'}
							disableHoverListener
							open={copyButtonTooltip === 'caption'}
						>
							<Button
								variant="contained"
								endIcon={<ContentCopy />}
								color={settings.theme === 'DARK' ? 'primary' : 'inherit'}
								sx={{ borderRadius: '5px', marginRight: '1rem' }}
								onClick={() => {
									setCaptionCopied();
									throwTooltip('caption');
								}}
							>
								Caption
							</Button>
						</Tooltip>
						<Tooltip
							arrow
							placement={'top'}
							title={'Copied!'}
							disableHoverListener
							open={copyButtonTooltip === 'tags'}
						>
							<Button
								variant="contained"
								endIcon={<ContentCopy />}
								color={settings.theme === 'DARK' ? 'primary' : 'inherit'}
								sx={{ borderRadius: '5px', marginRight: '1rem' }}
								onClick={() => {
									setHashtagsCopied();
									throwTooltip('tags');
								}}
							>
								Hashtags
							</Button>
						</Tooltip>
						<Tooltip
							arrow
							placement={'top'}
							title={'Copied!'}
							disableHoverListener
							open={copyButtonTooltip === 'both'}
							onClick={() => {
								setIsBothCopied();
								throwTooltip('both');
							}}
						>
							<Button
								variant="contained"
								endIcon={<ContentCopy />}
								color={settings.theme === 'DARK' ? 'primary' : 'inherit'}
								sx={{ borderRadius: '5px' }}
							>
								Both
							</Button>
						</Tooltip>
					</Box>
				)}
				<Box
					display="flex"
					justifyContent="flex-end"
					sx={{ mb: `${isVideoVaultPost ? '0' : '1rem'}`, columnGap: '1rem' }}
				>
					{!isVideoVaultPost && (
						<>
							<ImageUploadButton styles={{ borderRadius: '10px' }} />
							<Button
								size="large"
								endIcon={<CanvaIcon />}
								style={{ borderRadius: 10 }}
								variant={buttonStyle}
								onClick={() => {
									if (post?.node?.featuredPost?.canvalink) {
										window.open(post?.node?.featuredPost?.canvalink, '_blank');
									}
								}}
							>
								Edit In Canva
							</Button>
						</>
					)}
				</Box>
				<Box
					display="flex"
					justifyContent="flex-end"
					className={styles.buttonsContainer}
				>
					{!isVideoVaultPost && (
						<Button
							className={styles.modalButtons}
							endIcon={<ShareIcon />}
							variant="outlined"
							color="primary"
							onClick={() => setPublish(true)}
							sx={{ borderRadius: '5px', marginRight: '1rem', flexGrow: 1 }}
						>
							Publish Post
						</Button>
					)}
					<Button
						disabled={isUpdatingSavedPosts}
						className={styles.modalButtons}
						endIcon={isVideoVaultPost ? <CheckCircle /> : <FavoriteIcon />}
						variant={isPostSaved ? 'contained' : 'outlined'}
						color="primary"
						onClick={() => {
							setSavedPostsPayload(post.node.databaseId, isPostSaved);
							setIsPostSaved(!isPostSaved);
						}}
						sx={{ borderRadius: '5px', marginRight: '1rem' }}
					>
						{isPostSaved
							? isVideoVaultPost
								? 'Mark unused'
								: 'Unsave Post'
							: isVideoVaultPost
							? 'Mark used'
							: 'Save Post'}
					</Button>
					<Button
						className={styles.modalButtons}
						endIcon={<DownloadIcon />}
						variant="outlined"
						color="primary"
						onClick={() => {
							downloadImage();
						}}
						sx={{ borderRadius: '5px', marginRight: '1rem' }}
					>
						Download Post
					</Button>
					{isVideoVaultPost && (
						<>
							<Button
								size="large"
								endIcon={<CanvaIcon />}
								style={{ borderRadius: '5px', marginRight: '1rem' }}
								variant={buttonStyle}
								onClick={() => {
									if (post?.node?.featuredPost?.canvalink) {
										window.open(post?.node?.featuredPost?.canvalink, '_blank');
									}
								}}
							>
								Canva
							</Button>
							{post?.node?.featuredPost?.capcuttemplatelink && (
								<Button
									size="large"
									endIcon={<CapcutIcon />}
									style={{ borderRadius: '5px', marginRight: '1rem' }}
									variant={buttonStyle}
									onClick={() => {
										if (post?.node?.featuredPost?.capcuttemplatelink) {
											window.open(post?.node?.featuredPost?.capcuttemplatelink, '_blank');
										}
									}}
								>
									Capcut
								</Button>
							)}
						</>
					)}
					<Button
						className={styles.modalButtons}
						endIcon={<XIcon />}
						variant="outlined"
						color="primary"
						onClick={() => handleClose()}
						sx={{ borderRadius: '5px' }}
					>
						Close
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default PreviewMedia;
